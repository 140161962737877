/// PROFILE USER DATA
//////////////////////////
.profile-ud {
  &-label {
    display: block;
    color: $base-light;
    .plain & {
      font-size: $fx-sz-12;
      font-weight: $fw-medium;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
    .wider & {
      display: inline-flex;
      letter-spacing: -0.01em;
      width: 120px;
      flex-shrink: 0;
    }
  }
  &-value {
    color: $base-text;
    .wider & {
      text-align: left;
      font-weight: $fw-medium;
      width: 100%;
    }
  }
  &.wider {
    display: flex;
    padding: 0.5rem 0;
  }
  &.plain {
    .profile-ud-item:nth-child(n + 2) & {
      margin-top: 1.25rem;
    }
  }
}
@include media-breakpoint-up(md) {
  .profile-ud {
    &.wider {
      padding: 0.75rem 0;
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: -0.25rem -2.25rem;
      max-width: 100%;
    }
    &-item {
      width: 33%;
      padding-left: 2.25rem;
      &:nth-child(2) {
        .profile-ud.plain {
          margin-top: 0;
        }
      }
    }
  }
}

/// PROFILE STATS
//////////////////////////
.profile-stats {
  .amount {
    font-size: 1.125rem;
    font-weight: $fw-bold;
  }
}

/// PROFILE BALANCE
//////////////////////////
.profile-balance {
  &-group {
    display: flex;
    align-items: flex-end;
  }
  &-ck {
    position: relative;
    height: 70px;
    margin-top: 0.5rem;
  }
  &-sub {
    font-size: 0.875rem;
    color: $base-light;
    &title {
      font-size: 0.75rem;
      margin-top: 0.5rem;
    }
    .icon {
      font-size: 0.75rem;
    }
  }
  &-plus {
    position: absolute;
    .icon {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    ~ * {
      padding-left: 24px;
    }
  }
  &-amount {
    .number {
      font-size: 1.25rem;
      font-weight: $fw-bold;
      color: $base-color;
      line-height: 1.5rem;
      white-space: nowrap;
      small {
        font-weight: $fw-normal;
      }
    }
  }
}
