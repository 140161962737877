///////////////////////////////////////////////////////
///            CORE STYLE OF DASHLITE               ///
///////////////////////////////////////////////////////

/** 03. LAYOUTS */
@import "./layouts";

/** 04. UTILITES */
@import "./utilites";

/** 05. COMPONENTS */
@import "./components";

/** 06. PAGES */
@import "./pages";

/** 07. MISC */
@import "./misc";

.w {
  &-15 {
    width: 15% !important;
  }
}

.b {
  &-18 {
    bottom: 18px;
  }
}

.r {
  &-0 {
    right: 0;
  }
}
