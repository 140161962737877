.PaginationComponent-btns {
  height: 40px;
  list-style: none;
  display: flex;
  align-items: center;
}

.PaginationComponent-listItem,
.PaginationComponent-break {
  background-color: #ffffff;
  cursor: pointer;
}

.PaginationComponent-link,
.PaginationComponent-break {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 36.4px;
}

.PaginationComponent-listItem:hover,
.PaginationComponent-nextBtn:hover,
.PaginationComponent-prevBtn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.PaginationComponent-nextBtn,
.PaginationComponent-prevBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 36.4px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  cursor: pointer;
  color: #662a30;
  background: #ffffff;
  border: 1px solid #dbdfea;
}

.PaginationComponent-nextBtn {
  border-radius: 0;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.PaginationComponent-disabled {
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.4);
  cursor: not-allowed;
}

.PaginationComponent-active {
  color: #fff;
  background-color: #662a30;
}

.PaginationComponent-active:hover {
  color: #fff;
  background-color: #662a30;
}

.PaginationComponent-break {
  cursor: pointer;
}
