.badge-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 10rem;
  font-size: 0.675rem;
  font-weight: 500;
  width: fit-content;
  white-space: nowrap;
  font-size: 13px;
}

.font-color-white {
  color: #ffffff;
}

.font-color-black {
  color: #000000;
}
